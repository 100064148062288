.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #00357C; /* Blue background */
  color: white; /* White text */
  padding: 20px; /* Optional padding for spacing */
  border-radius: 8px; /* Optional border radius */
}

.game-info {
  text-align: center;
  max-width: 800px;
  margin-bottom: 20px;
}

.game-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.game-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: white; /* Foreground text white */
}

.gallery-container {
  width: 800px;
  max-width: 100%;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.button-group button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid white; /* White border */
  background-color: #004B8D; /* Slightly darker blue for buttons */
  color: white; /* White text */
  transition: background-color 0.3s;
}

.button-group button:hover {
  background-color: #005FAD; /* Lighter blue on hover */
}

.button-group .active {
  background-color: red; /* Active button green */
  color: white;
  font-weight: 1000;
}

.service-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin-top: 30px;
  text-align: left;
}

.form-item {
  flex: 1 1 calc(50% - 20px); /* Two items per row with a gap */
  background-color: #004B8D; /* Slightly darker blue */
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white; /* White text */
}

.form-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
}

.checkbox-list,
.dropdown-select {
  margin-top: 10px;
}

.checkbox-item,
.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox-title,
.dropdown-title {
  font-weight: bold;
}

.checkbox-description,
.dropdown-description {
  font-size: 0.9rem;
  color: #ccc;
}

.dropdown-select {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  color: #333;
  background-color: #f0f0f0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Styles specifically for counterService to occupy full row */
.counter-service {
  flex: 1 1 100%; /* Take up full width */
  background-color: #004B8D; /* Slightly darker blue */
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
}

.counter-service h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.counter-options {
  margin-top: 10px;
}

.counter-options .options-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option-checkbox {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 1rem;
  color: white;
}

.packages {
  margin-top: 20px;
}

.package-radio {
  display: block;
  margin-top: 10px;
  font-size: 1rem;
  color: white;
}

/* Total Price Section */
.total-price {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

/* Styles for numberInput and textInput */
.form-item-row {
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin-top: 10px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .form-item-row {
    flex-direction: column; /* Stack items vertically on mobile */
  }

  .form-item-half {
    flex: 1 1 100%; /* Each form item takes up the full width */
  }
}

.form-item-half {
  flex: 1 1 calc(50% - 20px); /* Each input takes up half the row with some gap */
  background-color: #004B8D; /* Slightly darker blue */
  padding: 15px;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
}

.form-item-half h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 10px;
}

.number-input,
.text-input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  color: #333;
  background-color: #f0f0f0;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.input-description {
  font-size: 0.9rem;
  color: #ccc;
  margin-top: 5px;
}

.number-input.error {
  border-color: red; /* Red border when there's an error */
}

.error-text {
  font-size: 0.9rem;
  color: red; /* Error text color */
  margin-top: 5px;
}
