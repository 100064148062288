/* Main gallery container */
.gallery-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

/* Main image container */
.gallery-container .image-gallery-slide-wrapper {
  max-width: 100%;
  max-height: 80vh; /* Occupies 80% of viewport height */
  height: 60vh;
  width: auto;
  /* height: auto; */
  overflow: hidden;
  margin: 0 auto;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Images fit dynamically within the container */
.gallery-container .image-gallery-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the entire image is visible */
}

/* Thumbnails adjust to container width */
.gallery-container .image-gallery-thumbnails-wrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 0; /* Add space between thumbnails and main image */
}

/* Thumbnail sizes */
.gallery-container .image-gallery-thumbnail {
  width: 80px; /* Default size for larger screens */
  height: auto;
}

.gallery-container .image-gallery-thumbnail img {
  max-width: 100%;
  object-fit: contain;
}

/* Navigation arrows */
.gallery-container .image-gallery-left-nav .image-gallery-svg,
.gallery-container .image-gallery-right-nav .image-gallery-svg {
  width: 40px; /* Default size */
  height: 40px;
}

/* Responsive adjustments */

/* Extra small devices (phones, less than 640px) */
@media (max-width: 639px) {
  .gallery-container .image-gallery-slide-wrapper {
    max-height: 60vh; /* Reduce height for smaller screens */
  }

  .gallery-container .image-gallery-thumbnail {
    width: 60px; /* Smaller thumbnails */
  }

  .gallery-container .image-gallery-left-nav .image-gallery-svg,
  .gallery-container .image-gallery-right-nav .image-gallery-svg {
    width: 30px;
    height: 30px;
  }
}

/* Small devices (tablets, 640px to 768px) */
@media (min-width: 640px) and (max-width: 768px) {
  .gallery-container .image-gallery-slide-wrapper {
    max-height: 70vh; /* Moderate height for tablets */
  }

  .gallery-container .image-gallery-thumbnail {
    width: 70px; /* Medium thumbnails */
  }
}

/* Medium devices (tablets in landscape, 769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .gallery-container .image-gallery-slide-wrapper {
    max-height: 75vh; /* Larger height for tablets in landscape */
  }
}

/* Larger devices (desktops, 1025px and up) */
@media (min-width: 1025px) {
  .gallery-container .image-gallery-slide-wrapper {
    max-height: 80vh; /* Larger height for desktops */
  }

  .gallery-container .image-gallery-thumbnail {
    width: 100px; /* Bigger thumbnails */
  }
}
